#features {
    padding: 20px;
  }
  
  .feature-content {
    display: flex;
    align-items: left; /* Align items vertically in the center */
    width: 100%;
    position: relative;
  }
  
  .image-container {
    flex: 0 0 70%; /* Fixed width of 30% */
    max-width: 30%;
    margin: 0 20px; /* Space between image and text */
  }
  
  .image-container img {
    width: 100%;
    height: auto;
    border-radius: 8px; /* Optional: rounded corners for the image */
  }
  
  .text-container {
    flex: 0 0 30%; /* Fixed width of 70% */
    max-width: 70%;
  }
  .red-color{
    color: red;
  }

  .div-center {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100px;
  }
  
  .btn-custom-download {
    font-family: "Raleway", sans-serif;
    text-transform: uppercase;
    margin-top: 10px;
    color: #fff;
    background-color: #5ca9fb;
    background-image: linear-gradient(to right, #6ffb5c 0%, #ff63c2 100%);
    padding: 14px 34px;
    letter-spacing: 1px;
    font-size: 15px;
    font-weight: 500;
    border-radius: 25px;
    transition: all 0.5s linear;
    border: 0;
  }

  .btn-custom-more {
    font-family: "Raleway", sans-serif;
    text-transform: uppercase;
    color: #fff;
    background-color: #5ca9fb;
    background-image: linear-gradient(to right, #6ffb5c 0%, #ff63c2 100%);
    letter-spacing: 1px;
    font-size: 15px;
    font-weight: 500;
    border-radius: 25px;
    transition: all 0.5s linear;
    border: 0;
  }