.flex-wrap{
    flex-wrap: wrap;
    flex-direction: column;
}
.mt-90{
    margin-top: 90px;
}
.m-0{
    margin: 0;
}
.mt-10{
    margin-top: 10px;
}

.year-header {
    position: relative;
    margin-top: 10px;
    margin-bottom: 15px;
    padding-bottom: 15px;
    font-size: x-large;
    font-weight: bold;
  }

  .year-header::after {
    position: absolute;
    content: "";
    background: linear-gradient(to right, #5c67fb 0%, #6372ff 100%);
    height: 4px;
    width: 100px;
    bottom: 0;
    margin-left: -50px;
    left: 50%;
  }
.newsletter-item{
    width: 100%;
    display: flex;
    margin-bottom: 30px;
    /* border-bottom: .5px solid #bcbcbc; */
    padding-bottom: 30px;
    justify-content: center;
}
.newsletter-item .image-container-abc {
    max-width: 12%;
    margin-right: 20px;
}
.symposium-item:nth-child(2n + 0){
    margin-right: 0;
}
.mb-2{
    margin-bottom: 2px;
}
.mb-30{
    margin-bottom: 30px;
}
.details{
    font-size: xx-large;
    font-weight: bolder;
}

 .newsletter-link {
    font-weight: bolder;
    color: #0082fb;
    text-decoration: underline;
}
