#features {
  padding: 20px;
}

.d-flex{
  display: flex;
}
.align-items-center{
  align-items: center;
}
.pr-10{
  padding-right: 10px;
}
.mt-30{
  margin: 30px 0;
}
.image-container-sym{
  max-width: 30%;
  width: 30%;
}
.image-container-sym img{
  width: 100%;
}

.text-container-sym{
  max-width: 70%;
  padding-left: 15px;
}
.text-container-sym p{
  text-align: left;
}

.feature-content {
  display: flex;
  align-items: center; /* Align items vertically in the center */
  width: 100%;
  position: relative;
}

.arrow-container {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.left-arrow {
  left: 0;
}

.right-arrow {
  right: 0;
}

.arrow-container button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.image-container {
  flex: 0 0 30%; /* Fixed width of 30% */
  max-width: 30%;
  margin: 0 20px; /* Space between image and text */
}

.image-container img {
  width: 100%;
  height: auto;
  border-radius: 8px; /* Optional: rounded corners for the image */
}

.text-container {
  flex: 0 0 70%; /* Fixed width of 70% */
  max-width: 70%;
}

/* #features h2::after {
  position: absolute;
  content: "";
  background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
  height: 4px;
  width: 60px;
  bottom: 0;
  left: 0;
} */

.btn-custom-symp-more {
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  margin-top: 10px;
  color: #fff;
  background-color: #5ca9fb;
  background-image: linear-gradient(to right, #dab70a 0%, #b61515 100%);
  padding: 14px 34px;
  letter-spacing: 1px;
  font-size: 15px;
  font-weight: 500;
  float: right;
  border-radius: 25px;
  transition: all 0.5s linear;
  border: 0;
}

.btn-custom-symp-all {
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  color: #fff;
  background-color: #5ca9fb;
  background-image: linear-gradient(to right, #3535bc 0%, #63c4ff 100%);
  letter-spacing: 1px;
  float:left;
  margin-left: 15px;
  font-size: 15px;
  font-weight: 500;
  border-radius: 25px;
  transition: all 0.5s linear;
  border: 0;
}

#symposium {
  padding: 100px 20px; /* Adjusted padding for better responsiveness */
}

.section-title {
  margin-bottom: 30px;
}

.feature-content {
  display: flex;
  flex-direction: column; /* Default to column layout for small screens */
  align-items: center;
  text-align: center;
}

.image-container {
  width: 100%;
  max-width: 100%; /* Ensure images take full width on mobile */
  margin-bottom: 20px; /* Space between image and text on small screens */
}

.image-container img {
  width: 100%;
  height: auto;
  border-radius: 8px; /* Optional: rounded corners for the image */
}

.text-container {
  width: 100%;
  max-width: 800px; /* Set a max-width for the text container */
}

@media (min-width: 768px) {
  .feature-content {
      flex-direction: row; /* Switch to row layout for larger screens */
      align-items: flex-start; /* Align text-container to the top */
      text-align: left; /* Align text to the left for larger screens */
  }

  .image-container {
      width: 30%;
      margin-right: 20px; /* Space between image and text */
  }

  .text-container {
      width: 70%;
  }
}

@media (min-width: 992px) {
  #symposium {
      padding: 100px 50px; /* Adjusted padding for larger screens */
  }
}

.donor-area {
  margin-top: 60px; /* Adjust as needed for spacing */
}

.donor-area h1 {
  text-align: center;
  margin-bottom: 30px; /* Space below the donor area title */
}

.donor-area .row {
  flex-wrap: wrap;
  justify-content: center;
}

.doner-team {
  margin-bottom: 30px; /* Adjust as needed for spacing */
  width: 100%; /* Full width on mobile screens */
}

.span {
  font-size: larger;
  color: blue;
  font-weight:bold;
  text-decoration: underline;
}
.left {
  float: left;
  text-align: left;
}
.bold-text {
  font-weight: bold;
}

.div-import h2::after {
  content: "";
  display: block;
  background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
  height: 2px;
  width: 100%;
  margin: 8px auto 0 auto; /* 8px space above the line, center the line */
}

@media (min-width: 768px) {
  .doner-team {
      width: 45%; /* Half-width for medium screens */
  }
}

@media (min-width: 992px) {
  .doner-team {
      width: 23%; /* Quarter-width for large screens */
  }
}
