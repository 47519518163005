.flex-wrap{
    flex-wrap: wrap;
}
.mt-90{
    margin-top: 90px;
}
.m-0{
    margin: 0;
}
.mt-10{
    margin-top: 10px;
}
.symposium-item{
    width: 100%;
    display: flex;
    
    margin-bottom: 30px;
    border-bottom: .5px solid #bcbcbc;
    padding-bottom: 30px;
}
.symposium-item .image-container-abc {
    max-width: 12%;
    margin-right: 20px;
}
.symposium-item:nth-child(2n + 0){
    margin-right: 0;
}
.mb-2{
    margin-bottom: 2px;
}
.mb-30{
    margin-bottom: 30px;
}
